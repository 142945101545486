import { CryptoPaymentRequest, CryptoPaymentResponse } from "../models";

interface Headers {
  "api-version": string;
  Authorization: string;
}

const post = async <T extends object>(
  url: string,
  body: T,
  headers?: Partial<Headers>
) =>
  fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "api-version": "0.2",
      ...headers,
    },
  });

const requestWrapper = async <T>(
  request: Promise<Response>
): Promise<T | null> => {
  try {
    const response = await request;

    if (!response.ok) {
      return null;
    }

    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

const BASE_URL = process.env.REACT_APP_BASE_URL;
const PAYMENT_CRYPTO_URL = `${BASE_URL}/Payments/crypto`;
const KEYCLOAK_URL = `${process.env.REACT_APP_KEYCLOAK_URL}/protocol/openid-connect/token`;
const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "";

export type LoginDetails = { username: string; password: string };

const login = async ({ username, password }: LoginDetails) => {
  const resp = await fetch(KEYCLOAK_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      username: username,
      password: password,
      grant_type: "password",
      client_id: KEYCLOAK_CLIENT_ID,
    }),
  });

  const content = await resp.json();

  return {
    idToken: content.access_token,
    refreshToken: content.refresh_token,
    expiresInSeconds: content.expires_in,
  };
};

const sendPaymentRequest = async (
  amount: number,
  requestData: Partial<CryptoPaymentRequest>,
  loginDetails: LoginDetails
) => {
  const loginResult = await login(loginDetails);

  if (!loginResult?.idToken) {
    throw Error("Token is empty");
  }

  const data = prepareData(amount, requestData);

  if (requestData.customer?.email.endsWith("@test.io")) {
    throw Error("This test email is not allowed");
  }

  return requestWrapper<CryptoPaymentResponse>(
    post(PAYMENT_CRYPTO_URL, data, {
      Authorization: `Bearer ${loginResult.idToken}`,
    })
  );
};

const prepareData = (
  requestedAmount: number,
  meta: Partial<CryptoPaymentRequest>
): Partial<CryptoPaymentRequest> => ({
  ...meta,
  requestedAmount,
});

export default sendPaymentRequest;
